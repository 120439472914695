import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import './BlogItem.scss'

const CondLink = ({ children, post }) => {
  if (post.link.includes('https://wordpress.collegepulse.com/2')) {
    return <Link to={`/blog/${post.slug}`}>{children}</Link>
  } else {
    return (
      <a target="_blank" href={post.link} rel="noreferrer">
        {children}
      </a>
    )
  }
}

export default ({ post }) => {
  return (
    <div className="column is-half is-one-third-widescreen">
      <CondLink post={post}>
        <div className="card blog-item-container">
          <div class="card-image">
            {post.featured_media && (
              <Img className="title" fluid={post.featured_media.localFile.childImageSharp.fluid} />
            )}
          </div>
          <div className="card-content">
            <h3
              className="title is-4 has-text-weight-bold is-marginless"
              dangerouslySetInnerHTML={{ __html: post.title }}
            />

            <p className="has-margin-top-1">
              <small
                className="has-text-weight-light"
                dangerouslySetInnerHTML={{ __html: post.date }}
              />
            </p>
            <div className="has-padding-3">
              <hr className="is-marginless" />
            </div>

            <p className="content" dangerouslySetInnerHTML={{ __html: post.excerpt }} />
          </div>
        </div>
      </CondLink>
    </div>
  )
}
