import React from 'react'
import { graphql } from 'gatsby'
import BlogItem from '../components/BlogItem/BlogItem'
import { Link } from 'gatsby'
import Layout from '../components/Layout/Layout'
import _ from 'lodash'

const Blog = ({ data, pageContext }) => (
  <Layout title={`College Pulse ${pageContext.category} Blog`}>
    <React.Fragment>
      <section className="has-background-primary has-padding-6 is-centered methodology-title">
        <h1 className="title has-text-white has-text-weight-bold has-margin-top-4 has-margin-bottom-3">
          {_.startCase(pageContext.category)}
          {pageContext.category === 'pulse-in-the-news' ? '' : ' Blog Posts'}
        </h1>
      </section>
      <main className="container has-padding-top-6 has-padding-bottom-6">
        <section className="columns is-multiline is-8 is-variable">
          {data.allWordpressPost.edges.map(post => (
            <BlogItem post={post.node} />
          ))}
        </section>

        <section className="has-margin-top-5" style={{ textAlign: 'center' }}>
          Currently on page {pageContext.currentPage} of {pageContext.pageCount}
          <br />
          {pageContext.currentPage !== 1 && (
            <Link className="has-margin-right-4" to={`/blog/page/${pageContext.currentPage - 1}`}>
              <strong>Newer Posts</strong>
            </Link>
          )}
          {pageContext.currentPage < pageContext.pageCount && (
            <Link to={`/blog/page/${pageContext.currentPage + 1}`}>
              <strong>Older Posts</strong>
            </Link>
          )}
        </section>
      </main>
    </React.Fragment>
  </Layout>
)

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $category: String!) {
    allWordpressPost(
      filter: { title: { ne: "dummy" }, categories: { elemMatch: { slug: { eq: $category } } } }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          date(formatString: "MMMM Do, YYYY")
          title
          excerpt
          slug
          link
          featured_media {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Blog
